import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const ProjectSiteSimple = ({
  children, title, text, pic,
}) => {
  const data = useStaticQuery(graphql`
    query ProjectSiteSimpleImages {
        images: allFile(filter: { relativeDirectory: { eq: "projects" } } ) {
            nodes {
                id
                childImageSharp {
                    fluid {
                    ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    }
  `)

  const image = data.images.nodes.find((n) => n.childImageSharp.fluid.src.includes(pic))

  return (
      <div className="project-site-simple">
          <div className="project-site-simple-prolog">
              <div className="project-site-simple-prolog-img-wrapper">
                  <Img fluid={image.childImageSharp.fluid} />
              </div>
              <div className="project-site-simple-prolog-title">
                  <p>{title}</p>
              </div>
              <div className="project-site-simple-prolog-text">
                  <p>{text}</p>
              </div>
          </div>
          <div className="project-site-simple-links">
              {children}
          </div>
      </div>
  )
}

ProjectSiteSimple.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  pic: PropTypes.string.isRequired,
}

ProjectSiteSimple.defaultProps = {
  children: null,
}

export default ProjectSiteSimple
