import React from 'react'
import PropTypes from 'prop-types'

const ProjectLinkCard = ({ title, path }) => (
    <div className="project-link-card">
        <a href={path} target="_blank" rel="noreferrer">
            <div className="project-link-card-title">
                <p>{title}</p>
            </div>
        </a>
    </div>
)

ProjectLinkCard.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
}

export default ProjectLinkCard
